import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  materialUsed: {
    materialUsedList: [], // Initialize as an object with materialUsedList
  },
};

export const productDetailsReducer = createSlice({
  name: '@productDetailsReducer',
  initialState,
  reducers: {
    Product_Deatils: (state, action) => {
      state.materialUsed.materialUsedList =
        action.payload.materialUsedList || [];
    },
    Data_Table: (state, action) => {
      state.materialUsed.materialUsedList =
        action.payload.materialUsedList || [];
    },
  },
});

export const { Product_Deatils, Data_Table } = productDetailsReducer.actions;

export default productDetailsReducer.reducer;
